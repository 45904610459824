export enum Modules {
  Dashboard = "Dashboard",
  /** Users */
  Users = "Users",
  /** User Wallet */
  UserWallet = "UserWallet",
  /**Market */
  Markets = "Markets",
  AllMarkets = "AllMarkets",
  MarketGameRate = "MarketGameRate",
  /** Market Result */
  MarketResults = "MarketResults",
  /** WithdrawalRequest */
  WithdrawalRequest = "WithdrawalRequest",
  PaymentGateway = "PaymentGateway",
  AllPaymentGateway = "AllPaymentGateway",
  PaymentConfig = "PaymentConfig",
  Payments = "Payments",
  /** Profit/Loss */
  ProfitLoss = "ProfitLoss",
  MarketProfitLoss = "MarketProfitLoss",
  Cutting = "Cutting",
  /** Feedback */
  Feedback = "Feedback",
  /** Settings */
  Settings = "Settings",
  Banners = "Banners",
  Roles = "Roles",
  RolePermission = "RolePermission",
  WithdrawalConfiguration = "WithdrawalConfiguration",
  Notifications = "Notifications",
  CMS = "CMS",
  AuthUsers = "AuthUsers",
  AdminUsers = "AdminUsers",
  /** starline market */
  StarLineMarkets = "StarLineMarkets",
  StarLineMarketProfitLoss = "StarLineMarketProfitLoss",
  StarLineMarketResults = "StarLineMarketResults",
  /** starline market Dashboard*/
  StarLineMarketDashboard = "StarLineMarketDashboard",
  StarLineDashboard = "StarLineDashboard",
  StarLineChart = "StarLineCharts",
  AllStarLineMarkets = "AllStarLineMarkets",
  StarLineMarketGameRate = "StarLineMarketGameRate",
  /** Analysis */
  Analysis = "Analysis",
  GoogleAnalysis = "GoogleAnalysis",
  MarketAnalysis = "MarketAnalysis",
  UserAnalysis = "UserAnalysis",
  /** DebitCreditAnalysis */
  DebitCreditAnalysis = "DebitCreditAnalysis",
}
